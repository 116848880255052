@font-face {
  font-family: "Exo2";
  src: local("Exo2"), url("./resources/Exo2-Medium.ttf") format("truetype");
  font-weight: 400;
}
html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}
textarea {
  height: 150px;
  line-height: 150%;
  resize:vertical;
}
::-ms-input-placeholder, :-ms-input-placeholder, ::placeholder {
  color: #ddd !important;
}
::placeholder {
  opacity: 1;
  color: #ddd !important;
}
.frc-captcha{
  background-color: transparent !important;
  border:none !important;
  margin:0 auto !important;
}
.frc-text{
  color:#eee !important;
}
.frc-icon path{
  fill:#eee !important;
}
.frc-banner{
  visibility: hidden !important;
}
.section{
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100vh;
  overflow-y:hidden;
  overflow-x:hidden;
  color:#eee;
  background-color: #010535;
}
.container{
  position:absolute;
  left:-10%;
  width:120%;
  height:100vh;
}
.layer{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100vh;
}
.layerimg{
  position:absolute;
  bottom:0;
  left:0;
  width:103%;
  height:103%;
  object-fit:cover;
}
.login{
  position:relative;
  padding:60px;

  margin-left:10px;
  margin-right:10px;
  font-family: "Exo2",Helvetica, Arial, sans-serif;
}
.sitecontent{
  height:90vh!important;
  background:rgba(255,255,255,0.15);
  backdrop-filter:blur(25px);
  border:1px solid rgba(255,255,255,0.5);
  border-bottom:1px solid rgba(255,255,255,0.25);
  border-left:1px solid rgba(255,255,255,0.25);
  border-radius:20px;
  box-shadow:0 25px 50px rgba(0,0,0,0.1);
}
.contactcontainer{
  display: flex;
  flex-direction: row;
}
.leftdiv{
  margin-right:60px;
  display:flex;
  align-items: center;
  font-size:18px;
  text-align: center;
  justify-content: center;
}
.mainlogo{
  margin-bottom:40px;
}
.dangerlogo{
  display: flex;
  justify-content: center;
}
.dangerlogo img{
  height:100px;
  width:100px;
}
.dangertxt{
  color:#ffcc00;
}
.feedbackinput {
  color:white;
  font-family: Helvetica, Arial, sans-serif;
  font-weight:500;
  font-size: 14px;
  border-radius: 5px;
  line-height: 14px;
  background-color: transparent;
  border:2px solid #ddd;
  transition: all 0.3s;
  padding: 10px;
  margin-top: 10px;
  width:100%;
  box-sizing: border-box;
  outline:0;
}
.feedbackinput:focus { border:2px solid #fff; }
.feedbackbutton {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  width: 100%;
  background:#eee;
  border-radius:5px;
  border:0;
  cursor:pointer;
  color:#5799d8;
  font-size:14px;
  padding-top:10px;
  padding-bottom:10px;
  transition: all 0.3s;
  font-weight:700;
  margin-top:10px;
}
.feedbackbutton:hover { background:#fff; }
.header{
  display:flex;
  flex-direction: row;
  padding-bottom:20px;
  border-bottom: 1px solid #999;
}
.makecenter{
  display:flex;
  justify-content: center;
}
.textdeveloper{
  color:#ccc;
  margin-bottom:20px;
  font-size:12px;
}
.textsocial{
  color:deeppink;
  margin-bottom:20px;
  font-size:18px;
}
.socialcontainer{
  display: flex;
  justify-content: space-evenly;
  gap:1rem;
}
.social-icons{
  position: relative;
  display: flex;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius:50%;
  background: #333;
  transition: .5s;
}
.social-icons img{
  align-self: center;
}
.social-icons::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ff1493;
  transition: .5s;
  transform: scale(.9);
  z-index: -1;
}
.social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #ff1493;
}
.social-icons:hover{
  color: #ffee10;
  box-shadow: 0 0 5px #ff1493;
  text-shadow: 0 0 5px #ff1493;
}
.mywebprojects{
  padding-bottom:20px;
  border-bottom: 1px solid #999;
}
.mygameprojects{
  padding-bottom:20px;
  border-bottom: 1px solid #999;
}
.sectiontexts{
  font-size:18px;
  text-align: center;
  margin-top:20px;
  margin-bottom:20px;
}
.cardholder{
  display:grid;
  grid-template-columns: auto auto;
  justify-items: center;
  gap:1rem;
}
.cardholder a{
  width: 320px;
  height: 180px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.32), 0 1px 2px rgba(0, 0, 0, 0.64);
  transition: all 300ms;
  color:#fff;
}
.cardholder a:hover{
  transform: translateY(-2px);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.45), 0 10px 10px rgba(0, 0, 0, 0.42);
}
.cardholder a .content{
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  height: auto;
  bottom: 0;
}
.cardholder a .content .title{
  background: linear-gradient(transparent, rgba(0, 0, 0, 1.0));
  height:64px;
  text-align: center;
  line-height:64px;
}

.cardholder a .cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
  }

  .card:hover span {
    transform: translateX(4px);
  }
}
/* Mobile */
@media (max-width: 800px) {
  .login{
    flex-direction:column;
  }
  .section{
    overflow-y:auto;
  }
  .leftdiv{
    margin-right:0px;

    padding-bottom:20px;
    margin-bottom:20px;
  }
  .header{
    flex-direction: column;
  }
  .cardholder{
    grid-template-columns: auto !important;
  }
  .contactcontainer{
    flex-direction: column;
  }
}

